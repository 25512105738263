import { useEffect, useState } from 'react';

import {
  Header,
  LogoPrimaryCP,
  Drawer,
  useDrawer,
  NavbarVertical,
  Button,
} from '@consumidor-positivo/aurora';

import { useSmartScript } from '@public-pages/smartscript';
import { useTracking } from '@services/tracking';

import './style.scss';

const headerData = {
  links: [
    {
      name: 'Início',
      href: '/',
    },
    {
      name: 'Quem somos',
      dropdown: [
        {
          name: 'Consumidor Positivo',
          href: 'https://www.consumidorpositivo.com.br/quem-somos',
        },
        {
          name: 'Acordo Certo',
          href: '/renegociacao',
        },
      ],
    },
    {
      name: 'Score',
      href: '/score?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
    },
    {
      name: 'Cadastro Positivo',
      href: '/cadastro-positivo?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
    },
    {
      name: 'Crédito',
      dropdown: [
        {
          name: 'Cartões de crédito',
          href: '/cartao-de-credito?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
        },
        {
          name: 'Empréstimo',
          href: '/emprestimo?utm_source=cross-sell&utm_medium=positive-consumer&utm_campaign=header-home&utm_content=logged-out',
        },
      ],
    },
    {
      name: 'Blog',
      href: '/blog?utm_campaign=header-home&utm_content=logged-out&utm_medium=positive-consumer&utm_source=cross-sell',
    },
  ],
  ctas: [
    {
      name: 'Cadastrar',
      href: 'https://app.acordocerto.com.br/cadastro',
      type: 'outlined',
    },
    {
      name: 'Consultar CPF',
      href: 'https://www.acordocerto.com.br/consultar-o-cpf',
      type: 'primary',
    },
  ],
};

const AuroraHeader = () => {
  const { drawerOpen, handleOpenDrawer } = useDrawer({ menu: false });

  const [currentPath, setCurrentPath] = useState<string | null>(null);

  const { generateOneLinkURL } = useSmartScript();
  const { elementClickedEvent } = useTracking();

  const isDesktop = () => window.innerWidth > 768;

  const handleModalAppCampaign = async () => {
    const clickURL = await generateOneLinkURL(
      'https://consumidorpositivo.onelink.me/3ote/pgvkj6v3',
      {
        af_sub3: JSON.stringify({
          type: 'link',
          path: location.pathname,
          location: 'header/baixe-o-app',
        }),
      }
    );

    const eventText = isDesktop()
      ? 'App Campaign header - Modal de App aberto'
      : `App Campaign header - Redirecionado para stores`;

    elementClickedEvent({
      elementType: 'button/text',
      text: eventText,
      location: window.location.pathname,
    });

    if (isDesktop()) {
      const event = new CustomEvent('openModalAppCampaign');
      window.dispatchEvent(event);
    } else {
      window.open(clickURL, '_top');
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentPath(window.location.pathname);
    }
  }, []);

  if (currentPath === null) {
    return null;
  }

  return (
    <Header.Root>
      <Header.Logo
        renderDesktop={<LogoPrimaryCP />}
        renderMobile={<LogoPrimaryCP />}
      />
      <Header.Navigation>
        <div className="header-action__navbar">
          <Header.Navbar
            data={headerData.links}
            renderItem={(item) => {
              let isActive = false;

              if (item.dropdown) {
                isActive = item.dropdown.some(
                  (dropdownItem) =>
                    currentPath ===
                    new URL(dropdownItem.href ?? '', window.location.origin)
                      .pathname
                );
              } else {
                const itemPath = new URL(
                  item.href ?? '',
                  window.location.origin
                ).pathname;
                isActive = currentPath === itemPath;
              }

              return (
                <Header.NavbarLink
                  key={item.name}
                  active={isActive}
                  href={item.href}
                  dropdown={item.dropdown}
                  name={item.name}
                  onClick={item.onClick}
                />
              );
            }}
          />
        </div>

        <Header.Actions>
          <div className="header-action__app">
            <Button onClick={() => handleModalAppCampaign()} type="outlined">
              <p className="header-action__app-text">Baixe o app</p>
            </Button>
          </div>

          <div className="header-action__consult">
            <Button
              as="a"
              href="https://app.consumidorpositivo.com.br/"
              target="_blank"
            >
              Consultar CPF
            </Button>
          </div>
          <Header.Hamburger onClick={() => handleOpenDrawer('menu')} />
        </Header.Actions>
      </Header.Navigation>
      <Drawer
        isOpen={drawerOpen.menu}
        handleOpen={() => handleOpenDrawer('menu')}
        renderHeader={<LogoPrimaryCP />}
        renderContent={
          <NavbarVertical
            data={headerData.links}
            renderItem={(link) => {
              let isActive = false;

              if (link.dropdown) {
                isActive = link.dropdown.some(
                  (dropdownItem) =>
                    currentPath ===
                    new URL(dropdownItem.href ?? '', window.location.origin)
                      .pathname
                );
              } else {
                const itemPath = new URL(
                  link.href ?? '',
                  window.location.origin
                ).pathname;
                isActive = currentPath === itemPath;
              }

              return (
                <NavbarVertical.Link
                  key={link.name}
                  active={isActive}
                  href={link.href}
                  dropdown={link.dropdown}
                  name={link.name}
                />
              );
            }}
            renderActions={() =>
              headerData.ctas.map((cta, index) => (
                <Button
                  key={index}
                  expand="x"
                  as="a"
                  href={cta.href}
                  type={cta.type as 'outlined' | 'primary'}
                >
                  {cta.name}
                </Button>
              ))
            }
          />
        }
      />
    </Header.Root>
  );
};

export default AuroraHeader;
